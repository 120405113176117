import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "name",
      model: "name",
      required: false,
      placeholder: "name",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("nameRequired"),
          textTooBig: i18n.t("nameMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "token",
      model: "token",
      required: true,
      placeholder: "token",
      max: 256,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("tokenRequired"),
          textTooBig: i18n.t("tokenMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "select",
      label: "status",
      model: "status",
      required: true,
      selectOptions: {
        noneSelectedText: i18n.t("chooseStatus"),
      },
      values: [
        { name: i18n.t("Active"), id: 1 },
        { name: i18n.t("Hidden"), id: 0 },
      ],
    },
  ]
};
